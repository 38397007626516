import React, { useState } from 'react';
import Main from '../Componentes/Main';
import { Link } from 'react-router-dom';

import { useAuth } from '../Context/Auth';
import { useError } from '../Context/Error';

export default function Login({ history }) {
  const [emailYPassword, setEmailYPassword] = useState({
    email: '',
    password: ''
  });
  const { login } = useAuth();
  const { showError } = useError();

  function handleInputChange(e) {
    const { name, value } = e.target;
    setEmailYPassword({ ...emailYPassword, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      await login(emailYPassword.email, emailYPassword.password);
      history.push('/');
    } catch (error) {
      showError(error.response.data);
    }
  }

  return (
    <Main center>
      <div className="FormContainer">
        <h1 className="Form__titulo">Clontagram</h1>
        <div>
          <form onSubmit={handleSubmit}>
            <input
              name="email"
              onChange={handleInputChange}
              type="email"
              placeholder="Email"
              className="Form__field"
              required
            />
            <input
              name="password"
              onChange={handleInputChange}
              type="password"
              placeholder="Password"
              className="Form__field"
              required
            />
            <button type="submit" className="Form__submit">
              Login
            </button>
            <p className="FormContainer__info">
              No tienes cuenta? <Link to="/signup">Signup</Link>
            </p>
          </form>
        </div>
      </div>
    </Main>
  );
}
