import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../Context/Auth';
import { useError } from '../Context/Error';

import img from '../img/signup.png';
import Main from '../Componentes/Main';

export default function Signup({ history }) {
  const [usuario, setUsuario] = useState({
    email: '',
    password: '',
    bio: '',
    username: '',
    nombre: ''
  });

  const { signup } = useAuth();
  const { showError } = useError();

  function handleInputChange(e) {
    const { name, value } = e.target;
    setUsuario({ ...usuario, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      await signup(usuario);
      history.push('/');
    } catch (error) {
      showError(error.response.data);
    }
  }

  return (
    <Main center>
      <div className="Signup">
        <img className="Signup__img" src={img} alt="" />
        <div className="FormContainer">
          <h1 className="Form__titulo">Clontagram</h1>
          <p className="FormContainer__info">
            Regístrate para que veas el clon de Instagram
          </p>
          <form onSubmit={handleSubmit}>
            <input
              name="email"
              type="email"
              placeholder="Email"
              className="Form__field"
              onChange={handleInputChange}
              required
            />
            <input
              name="nombre"
              type="text"
              placeholder="Nombre y apellido"
              className="Form__field"
              onChange={handleInputChange}
              required
              minLength="3"
              maxLength="100"
            />
            <input
              name="username"
              type="text"
              placeholder="Username"
              className="Form__field"
              onChange={handleInputChange}
              required
              minLength="3"
              maxLength="30"
            />
            <input
              name="bio"
              type="text"
              placeholder="Cuétanos de ti..."
              className="Form__field"
              onChange={handleInputChange}
              required
              maxLength="150"
            />
            <input
              name="password"
              type="password"
              placeholder="Password"
              className="Form__field"
              onChange={handleInputChange}
              required
            />
            <button className="Form__submit" type="submit">
              Sign up
            </button>
            <p className="FormContainer__info">
              Ya tienes cuenta? <Link to="/login">Login</Link>
            </p>
          </form>
        </div>
      </div>
    </Main>
  );
}
