import React from 'react';

export default function() {
  return (
    <div className="Loading">
      <div className="Loading__dot-1" />
      <div className="Loading__dot-2" />
      <div className="Loading__dot-3" />
    </div>
  );
}
