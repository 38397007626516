import React, { useState, useMemo } from 'react';

const ErrorContext = React.createContext();

export function ErrorProvider(props) {
  const [error, setError] = useState('');

  const value = useMemo(() => {
    return { showError, hideError, error };
  }, [error]);

  function showError(err) {
    setError(err);
  }

  function hideError() {
    setError('');
  }

  return <ErrorContext.Provider value={value} {...props} />;
}

export function useError() {
  const context = React.useContext(ErrorContext);
  if (!context) {
    throw new Error('useError debe usarse dentro de un Error');
  }
  return context;
}
