import React, { useState, useEffect, useCallback, useRef } from 'react';
import Main from '../Componentes/Main';
import Post from '../Componentes/Post';
import Axios from 'axios';
import Loading from '../Componentes/Loading';
import { useError } from '../Context/Error';
import { Link } from 'react-router-dom';

const NUMERO_POSTS_POR_LLAMADA = 3;

async function cargarPosts(fechaDeUltimoPost) {
  const query = fechaDeUltimoPost ? `?fecha=${fechaDeUltimoPost}` : '';
  const { data: nuevosPosts } = await Axios.get(`/api/posts/feed${query}`);

  return nuevosPosts;
}

export default function Feed() {
  const [posts, setPosts] = useState([]);
  const [cargandoPostIniciales, setCargandoPostIniciales] = useState(true);
  const [cargandoMasPosts, setCargandoMasPosts] = useState(false);
  const [todosLosPostsCargados, setTodosLosPostsCargados] = useState(false);
  const { showError } = useError();

  useEffect(() => {
    // Eventualmente agregar variable que verifique si el componente se encuentra montado
    async function cargarPostsIniciales() {
      try {
        const nuevosPosts = await cargarPosts();
        setPosts(nuevosPosts);
        revisarSiHayMasPosts(nuevosPosts);
        setCargandoPostIniciales(false);
      } catch (error) {
        showError('Hubo un problema cargando tu feed.');
        setCargandoPostIniciales(false);
      }
    }

    cargarPostsIniciales();
  }, []);

  async function cargarMasPosts() {
    try {
      if (cargandoMasPosts) {
        return;
      }

      setCargandoMasPosts(true);
      const fechaDeUltimoPost = posts[posts.length - 1].fecha_creado;
      const nuevosPosts = await cargarPosts(fechaDeUltimoPost);
      setPosts(viejosPosts => [...viejosPosts, ...nuevosPosts]);
      revisarSiHayMasPosts(nuevosPosts);
      setCargandoMasPosts(false);
    } catch (error) {
      showError('Hubo un problema cargando los siguientes posts.');
      setCargandoMasPosts(false);
    }
  }

  function revisarSiHayMasPosts(nuevosPosts) {
    if (nuevosPosts.length < NUMERO_POSTS_POR_LLAMADA) {
      setTodosLosPostsCargados(true);
    }
  }

  // Esto esta ultra optimizado. No hace falta hacerlo así en el curso.
  // En Post.js tengo un React.memo para evitar el rerender
  const actualizarPost = useCallback((postViejo, postActualizado) => {
    setPosts(posts => {
      const postsActualizados = posts.map(post => {
        if (post !== postViejo) {
          return post;
        }

        return postActualizado;
      });

      return postsActualizados;
    });
  }, []);

  if (cargandoPostIniciales) {
    return (
      <Main center>
        <Loading />
      </Main>
    );
  }

  if (posts.length === 0) {
    return (
      <Main center>
        <NoSiguesANadie />
      </Main>
    );
  }

  return (
    <Main>
      <div className="Feed">
        {posts.map(post => (
          <Post key={post._id} actualizarPost={actualizarPost} post={post} />
        ))}
        <CargarMasPosts
          todosLosPostsCargados={todosLosPostsCargados}
          onClick={cargarMasPosts}
        />
      </div>
    </Main>
  );
}

function NoSiguesANadie() {
  return (
    <div className="NoSiguesANadie">
      <p className="NoSiguesANadie__mensaje">
        Tu feed no tiene fotos porque no sigues a nadie, o porque no han
        publicado fotos.
      </p>
      <div className="text-center">
        <Link to="/explore" className="NoSiguesANadie__boton">
          Explora Clontagram
        </Link>
      </div>
    </div>
  );
}

function CargarMasPosts({ onClick, todosLosPostsCargados }) {
  if (todosLosPostsCargados) {
    return <div className="Feed__no-hay-mas-posts">No hay más posts</div>;
  }

  return (
    <button className="Feed__cargar-mas" onClick={onClick}>
      Ver más
    </button>
  );
}
