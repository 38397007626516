import Axios from 'axios';

export default function useLike() {
  async function toggleLike(post) {
    let postConLikeActualizado;

    if (post.estaLike) {
      await Axios.delete(`/api/posts/${post._id}/likes`, {});
      postConLikeActualizado = {
        ...post,
        numLikes: post.numLikes - 1,
        estaLike: false
      };
    } else {
      await Axios.post(`/api/posts/${post._id}/likes`, {});
      postConLikeActualizado = {
        ...post,
        numLikes: post.numLikes + 1,
        estaLike: true
      };
    }

    return postConLikeActualizado;
  }

  return toggleLike;
}
