import React, { useState, useEffect } from 'react';
import Axios from 'axios';

import Main from '../Componentes/Main';
import Loading from '../Componentes/Loading';
import {
  initAxiosInterceptors,
  getToken,
  deleteToken,
  setToken
} from '../Helpers/auth-helpers';

const AuthContext = React.createContext();
initAxiosInterceptors();

export function AuthProvider(props) {
  const [usuario, setUsuario] = useState(null);
  const [cargandoUsuario, setCargandoUsuario] = useState(true);

  useEffect(() => {
    async function cargarUsuario() {
      if (!cargandoUsuario) {
        return;
      }

      if (!getToken()) {
        setCargandoUsuario(false);
        return;
      }

      try {
        const { data: usuario } = await Axios.get('/api/usuarios/whoami');
        setUsuario(usuario);
        setCargandoUsuario(false);
      } catch (error) {
        console.log(error);
      }
    }
    cargarUsuario();
  }, [cargandoUsuario]);

  async function login(email, password) {
    const { data } = await Axios.post('/api/usuarios/login', {
      email,
      password
    });
    setToken(data.token);
    setUsuario(data.usuario);
  }

  async function signup(usuario) {
    const { data } = await Axios.post('/api/usuarios/signup', usuario);
    setToken(data.token);
    setUsuario(data.usuario);
  }

  function logout() {
    deleteToken(null);
    setUsuario(null);
  }

  if (cargandoUsuario) {
    return (
      <Main center>
        <Loading />
      </Main>
    );
  }

  return (
    <AuthContext.Provider
      value={{ login, signup, usuario, logout, cargandoUsuario }}
      {...props}
    />
  );
}

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth debe usarse dentro de un Auth');
  }
  return context;
}
