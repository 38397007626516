import React, { useState, useEffect } from 'react';

import Main from '../Componentes/Main';
import Grid from '../Componentes/Grid';
import RecursoNoExiste from '../Componentes/RecursoNoExiste';
import Axios from 'axios';
import stringToColor from 'string-to-color';

import { useAuth } from '../Context/Auth';
import useEsMobil from '../Hooks/useEsMobil';
import useAmistad from '../Hooks/useAmistad';
import { useError } from '../Context/Error';
import Loading from '../Componentes/Loading';

export default function Perfil({ match }) {
  const { usuario: usuarioLoggedIn, logout } = useAuth();
  const [usuario, setUsuario] = useState(null);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [enviandoAmistad, setEnviandoAmistad] = useState(false);
  const [subiendoImagen, setSubiendoImagen] = useState(false);
  const [perfilNoExiste, setPerfilNoExiste] = useState(false);
  const toggleSiguiendo = useAmistad(usuario);
  const { showError } = useError();
  const username = match.params.username;
  const esMobil = useEsMobil();

  useEffect(() => {
    async function cargarPostsYUsuario() {
      try {
        setLoading(true);
        const { data: usuario } = await Axios.get(`/api/usuarios/${username}`);
        const { data: posts } = await Axios.get(
          `/api/posts/usuario/${usuario._id}`
        );

        setUsuario(usuario);
        setPosts(posts);
        setLoading(false);
      } catch (error) {
        console.log(error);
        if (
          error.response &&
          (error.response.status === 404 || error.response.status === 400)
        ) {
          setPerfilNoExiste(true);
        } else {
          showError('Hubo un problema cargando este perfil.');
        }
        setLoading(false);
      }
    }

    cargarPostsYUsuario();
  }, [username]); // Mostrar que ir de un usuario en explore a tu perfil no refresca a menos que esto este aquí

  async function onToggleSiguiendo() {
    if (enviandoAmistad) {
      return;
    }

    try {
      setEnviandoAmistad(true);
      const usuarioActualizado = await toggleSiguiendo(usuario);
      setUsuario(usuarioActualizado);
      setEnviandoAmistad(false);
    } catch (e) {
      setEnviandoAmistad(false);
      showError(
        'Hubo un problema siguiendo/dejando de seguir a este usuario. Intenta de nuevo.'
      );
      console.log(e);
    }
  }

  function esMiPerfil() {
    if (usuarioLoggedIn == null) return false;
    if (usuario == null) return false;

    return usuarioLoggedIn._id === usuario._id;
  }

  async function handleImagenSeleccionada(e) {
    try {
      setSubiendoImagen(true);
      const file = e.target.files[0];

      const config = {
        headers: {
          'Content-Type': file.type
        }
      };

      const { data } = await Axios.post('/api/usuarios/upload', file, config);
      setUsuario({ ...usuario, imagen: data.url });

      setSubiendoImagen(false);
    } catch (e) {
      setSubiendoImagen(false);
      showError(e.response.data);
      console.log(e);
    }
  }

  if (loading) {
    return (
      <Main center>
        <Loading />
      </Main>
    );
  }

  if (perfilNoExiste) {
    return (
      <RecursoNoExiste mensaje="El perfil que estas intentando ver no existe" />
    );
  }

  if (usuario == null) {
    return null;
  }

  return (
    <Main>
      <div className="Perfil">
        <ImagenAvatar
          usuario={usuario}
          handleImagenSeleccionada={handleImagenSeleccionada}
          esMiPerfil={esMiPerfil()}
          subiendoImagen={subiendoImagen}
        />
        <div className="Perfil__bio-container">
          <div className="Perfil__bio-heading">
            <h2 className="capitalize">{usuario.username}</h2>
            {!esMiPerfil() && (
              <BotonSeguir
                siguiendo={usuario.siguiendo}
                toggleSiguiendo={onToggleSiguiendo}
              />
            )}
            {esMiPerfil() && <BotonLogout logout={logout} />}
          </div>
          {!esMobil && <DescripcionPerfil usuario={usuario} />}
        </div>
      </div>
      {esMobil && <DescripcionPerfil usuario={usuario} />}

      <div className="Perfil__separador" />
      {posts.length > 0 ? <Grid posts={posts} /> : <NoHaPosteadoFotos />}
    </Main>
  );
}

function DescripcionPerfil({ usuario }) {
  return (
    <div className="Perfil__descripcion">
      <h2 className="Perfil__nombre">{usuario.nombre}</h2>
      <p>{usuario.bio}</p>
      <p className="Perfil__estadisticas">
        <b>{usuario.numSiguiendo}</b> following
        <span className="ml-4">
          <b>{usuario.numSeguidores}</b> followers
        </span>
      </p>
    </div>
  );
}

function BotonSeguir({ siguiendo, toggleSiguiendo }) {
  if (siguiendo == null) {
    return null;
  }

  return (
    <button onClick={toggleSiguiendo} className="Perfil__boton-seguir">
      {siguiendo ? 'Dejar de seguir' : 'Seguir'}
    </button>
  );
}

function BotonLogout({ logout }) {
  return (
    <button onClick={logout} className="Perfil__boton-logout">
      Logout
    </button>
  );
}

function NoHaPosteadoFotos() {
  return <p className="text-center">Este usuario no ha posteado fotos.</p>;
}

function ImagenAvatar({
  esMiPerfil,
  usuario,
  handleImagenSeleccionada,
  subiendoImagen
}) {
  let contenido;

  if (subiendoImagen) {
    contenido = <Loading />;
  } else if (esMiPerfil) {
    contenido = (
      <label
        className="Perfil__img-placeholder Perfil__img-placeholder--pointer"
        style={{
          backgroundImage: usuario.imagen ? `url(${usuario.imagen})` : null,
          backgroundColor: stringToColor(usuario.username)
        }}
      >
        <input
          onChange={handleImagenSeleccionada}
          type="file"
          className="hidden"
          name="imagen"
        />
      </label>
    );
  } else {
    contenido = (
      <div
        className="Perfil__img-placeholder"
        style={{
          backgroundImage: usuario.imagen ? `url(${usuario.imagen})` : null,
          backgroundColor: stringToColor(usuario.username)
        }}
      />
    );
  }

  return <div className="Perfil__img-container">{contenido}</div>;
}
