import React from 'react';
import { useError } from '../Context/Error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

export default function Error() {
  let { hideError, error } = useError();

  if (!error) {
    return null;
  }

  if (error.message) {
    error = error.message;
  }

  return (
    <div className="ErrorContainer" role="alert">
      <div className="Error__inner">
        <span className="block">{error}</span>
        <button onClick={hideError} className="Error__button">
          <FontAwesomeIcon className="Error__icon" icon={faTimesCircle} />
        </button>
      </div>
    </div>
  );
}
