import { useCallback } from 'react';
import Axios from 'axios';
import { useAuth } from '../Context/Auth';

export default function useComentar() {
  const usuarioLoggedIn = useAuth().usuario;
  const comentar = useCallback(
    async (post, mensaje) => {
      const { data: nuevoComentario } = await Axios.post(
        `/api/posts/${post._id}/comentarios`,
        { mensaje }
      );
      nuevoComentario.usuario = usuarioLoggedIn;

      const nuevoPost = {
        ...post,
        comentarios: [...post.comentarios, nuevoComentario],
        numComentarios: post.numComentarios + 1
      };

      return nuevoPost;
    },
    [usuarioLoggedIn]
  );

  return comentar;
}
