import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import Main from '../Componentes/Main';
import Loading from '../Componentes/Loading';
import { useError } from '../Context/Error';
import Axios from 'axios';

export default function Upload({ history }) {
  const [imagenUrl, setImagenUrl] = useState('');
  const [caption, setCaption] = useState('');
  const [subiendoImagen, setSubiendoImage] = useState(false);
  const [enviandoPost, setEnviandoPost] = useState(false);
  const { showError } = useError();

  async function handleImagenSeleccionada(e) {
    try {
      setSubiendoImage(true);
      const file = e.target.files[0];

      const config = {
        headers: {
          'Content-Type': file.type
        }
      };

      const { data } = await Axios.post('/api/posts/upload', file, config);
      setImagenUrl(data.url);
      setSubiendoImage(false);
    } catch (e) {
      setSubiendoImage(false);
      showError(e.response.data);
      console.log(e);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (enviandoPost) {
      return;
    }

    if (subiendoImagen) {
      showError('No se ha terminado de subir la imagen');
      return;
    }

    if (!imagenUrl) {
      showError('Primero seleccionar una imagen');
      return;
    }

    try {
      setEnviandoPost(true);
      const body = {
        caption,
        url: imagenUrl
      };
      await Axios.post('/api/posts', body);
      setEnviandoPost(false);
      history.push('/');
    } catch (e) {
      showError(e.response.data);
    }
  }

  return (
    <Main>
      <div className="Upload">
        <form onSubmitCapture={handleSubmit}>
          <div className="Upload__image-section">
            <SeccionSubirImage
              subiendoImagen={subiendoImagen}
              imagenUrl={imagenUrl}
              handleImagenSeleccionada={handleImagenSeleccionada}
            />
          </div>
          <textarea
            className="Upload__caption"
            id="username"
            type=""
            placeholder="Caption"
            name="caption"
            value={caption}
            onChange={e => setCaption(e.target.value)}
            required
            maxLength="180"
          />
          <button type="submit" className="Upload__submit">
            Post
          </button>
        </form>
      </div>
    </Main>
  );
}

function SeccionSubirImage({
  subiendoImagen,
  imagenUrl,
  handleImagenSeleccionada
}) {
  if (subiendoImagen) {
    return <Loading />;
  } else if (imagenUrl) {
    return <img src={imagenUrl} alt="" />;
  } else {
    return (
      <label className="Upload__image-label">
        <FontAwesomeIcon icon={faUpload} />
        <span>Publica una foto</span>
        <input
          onChange={handleImagenSeleccionada}
          type="file"
          className="hidden"
          name="imagen"
        />
      </label>
    );
  }
}
