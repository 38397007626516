import React from 'react';
import Main from '../Componentes/Main';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <Main center>
      <div>
        <h2 className="text-center px-4">
          Lo sentimos, pero está página no existe.
        </h2>
        <p className="text-center mt-4">
          Ir al <Link to="/">home</Link>
        </p>
      </div>
    </Main>
  );
}
