import React, { useState } from 'react';
import { useError } from '../Context/Error';

export default function Comentar({ onSubmitComentario }) {
  const [enviandoComentario, setEnviandoComentario] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const { showError } = useError();

  async function onSubmit(e) {
    e.preventDefault();

    if (enviandoComentario) {
      return;
    }

    try {
      setEnviandoComentario(true);
      await onSubmitComentario(mensaje);
      setMensaje('');
      setEnviandoComentario(false);
    } catch (e) {
      setEnviandoComentario(false);
      showError('Hubo un problema guardando el comentario. Intenta de nuevo.');
      console.log(e);
    }
  }

  return (
    <form className="Post__comentario-form-container" onSubmit={onSubmit}>
      <input
        type="text"
        placeholder="deja un comentario..."
        value={mensaje}
        onChange={e => setMensaje(e.target.value)}
        required
        maxLength="180"
      />
      <button type="submit">Post</button>
    </form>
  );
}
