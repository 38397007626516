import React, { useState, memo } from 'react';
import { Link } from 'react-router-dom';
import useLike from '../Hooks/useLike';
import Avatar from '../Componentes/Avatar';
import Comentar from '../Componentes/Comentar';
import BotonLike from '../Componentes/BotonLike';
import useComentar from '../Hooks/useComentar';
import { useError } from '../Context/Error';

function Post({ post, actualizarPost }) {
  const {
    numLikes,
    numComentarios,
    comentarios,
    _id,
    caption,
    url,
    usuario,
    estaLike
  } = post;
  const [enviandoLike, setEnviandoLike] = useState(false);
  const toggleLike = useLike();
  const comentar = useComentar();
  const { showError } = useError();

  async function onSubmitComentario(comentario) {
    const postConComentario = await comentar(post, comentario);
    actualizarPost(post, postConComentario);
  }

  async function onSubmitLike() {
    if (enviandoLike) {
      return;
    }

    try {
      setEnviandoLike(true);
      const postConLike = await toggleLike(post);
      actualizarPost(post, postConLike);
      setEnviandoLike(false);
    } catch (e) {
      setEnviandoLike(false);
      showError('Hubo un problema modificando el like. Intenta de nuevo.');
      console.log(e);
    }
  }

  return (
    <div className="Post-Componente">
      <Avatar usuario={usuario} />
      <img className="Post-Componente__img" src={url} alt={caption} />

      <div className="Post-Componente__acciones">
        <div className="Post-Componente__like-container">
          <BotonLike onSubmitLike={onSubmitLike} like={estaLike} />
        </div>
        <p>Liked por {numLikes} personas</p>
        <ul>
          <li>
            <Link to={`/perfil/${usuario.username}`}>
              <b>{usuario.username}</b>
            </Link>{' '}
            {caption}
          </li>
          {numComentarios > 3 && (
            <li className="text-grey-dark">
              <Link to={`/post/${_id}`}>
                Ver los {numComentarios} comentarios
              </Link>
            </li>
          )}
          {comentarios.slice(-3).map(comentario => {
            return (
              <li key={comentario._id}>
                <Link to={`/perfil/${comentario.usuario.username}`}>
                  <b>{comentario.usuario.username}</b>
                </Link>{' '}
                {comentario.mensaje}
              </li>
            );
          })}
        </ul>
      </div>
      <Comentar onSubmitComentario={onSubmitComentario} />
    </div>
  );
}

export default memo(Post);
