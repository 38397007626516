import React, { useState } from 'react';
import { Dialog } from '@reach/dialog';

export default function CreadoPorAppdelante() {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <button
        className="fixed pin-b pin-r shadow-lg rounded p-4 mr-4 mb-6 z-50 rounded-full bg-white text-sm"
        onClick={() => setOpen(!isOpen)}
      >
        ¿Quieres crear <br /> esta app?
      </button>
      <Dialog isOpen={isOpen} onDismiss={() => setOpen(false)}>
        <div>
          <div className="text-2xl">Hola 👋</div>
          <h1 className="text-xl mt-4">
            ¿Quieres aprender a crear esta aplicación?
          </h1>
          <p className="mt-2">
            Estamos creando un curso. Deja tu correo en{' '}
            <a href="https://appdelante.com/noticias" target="blank">
              Appdelante
            </a>{' '}
            para avisarte cuando este listo.
          </p>
          <div className="flex items-center justify-center mt-4">
            <a
              className="px-4 py-2 bg-blue text-white rounded no-underline hover:bg-blue-dark"
              href="https://appdelante.com/noticias"
              target="blank"
            >
              Avísame cuando este listo
            </a>
          </div>
        </div>
      </Dialog>
    </>
  );
}
