import React, { useRef, useContext, useState } from 'react';
import Rect, { useRect } from '@reach/rect';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons';

import {
  faCompass,
  faUser,
  faHeart
} from '@fortawesome/free-regular-svg-icons';

import { useAuth } from '../Context/Auth';
import Notificaciones from './Notificaciones';

export default function Nav() {
  const { usuario } = useAuth();

  return (
    <nav className="Nav">
      <ul className="Nav__links">
        <li>
          <Link to="/" className="Nav__link">
            <span>Clontagram</span>
          </Link>
        </li>
        {usuario && <LoginRoutes username={usuario.username} />}
      </ul>
    </nav>
  );
}

function LoginRoutes({ username }) {
  const [mostrarNotificaciones, setMostrarNotificaciones] = useState(false);
  // const ref = useRef();
  // const rect = useRect(ref);

  function toggleNotificaciones() {
    setMostrarNotificaciones(!mostrarNotificaciones);
  }

  return (
    <>
      <li className="Nav__link-push">
        <Link to="/upload" className="Nav__link">
          <FontAwesomeIcon icon={faCameraRetro} />
          <i className="fas fa-camera-retro" />
        </Link>
      </li>
      <li className="Nav__link-margin-left">
        <Link to="/explore" className="Nav__link">
          <FontAwesomeIcon icon={faCompass} />
        </Link>
      </li>
      {/* <li className="Nav__link-margin-left" ref={ref}>
        <button onClick={toggleNotificaciones}>
          <FontAwesomeIcon icon={faHeart} />
        </button>
        {mostrarNotificaciones && (
          <Notificaciones
            rect={rect}
            toggleNotificaciones={toggleNotificaciones}
          />
        )}
      </li> */}
      <li className="Nav__link-margin-left">
        <Link to={`/perfil/${username}`} className="Nav__link">
          <FontAwesomeIcon icon={faUser} />
        </Link>
      </li>
    </>
  );
}
