import React from 'react';
import { AuthProvider } from './Auth';
import { ErrorProvider } from './Error';

function AppProviders({ children }) {
  return (
    <AuthProvider>
      <ErrorProvider>{children}</ErrorProvider>
    </AuthProvider>
  );
}

export default AppProviders;
