import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { useAuth } from './Context/Auth';

import Nav from './Componentes/Nav';
import Error from './Componentes/Error';
import CreadoPorAppdelante from './Componentes/CreadoPorAppdelante';

import Feed from './Vistas/Feed';
import Upload from './Vistas/Upload';
import Perfil from './Vistas/Perfil';
import Explore from './Vistas/Explore';
import Login from './Vistas/Login';
import Signup from './Vistas/Signup';
import Post from './Vistas/Post';
import NotFound from './Vistas/NotFound';
import Main from './Componentes/Main';
import Loading from './Componentes/Loading';

export default function App() {
  const { usuario, cargandoUsuario } = useAuth();

  if (cargandoUsuario) {
    return (
      <Main center>
        <Loading />
      </Main>
    );
  }

  return (
    <Router>
      <Nav />
      <Error />
      {usuario ? <LoginRoutes /> : <LogoutRoutes />}
      <CreadoPorAppdelante />
    </Router>
  );
}

function LoginRoutes() {
  return (
    <Switch>
      <Route path="/" exact component={Feed} />
      <Route path="/perfil/:username?" component={Perfil} />
      <Route path="/post/:id" component={Post} />
      <Route path="/upload/" component={Upload} />
      <Route path="/explore/" component={Explore} />
      <Route component={NotFound} />
    </Switch>
  );
}

function LogoutRoutes() {
  return (
    <Switch>
      <Route path="/login/" component={Login} />
      <Route component={Signup} default />
    </Switch>
  );
}
